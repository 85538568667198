<template>
    <section class="main-content-section">
        <div class="grid">
            <LoadingSpinner v-if="isLoading"/>

            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container authentication">
                    
                    <h1 class="page-title">{{resetPasswordLabel}}</h1>
                    <div class="info">{{fillInTheFormBelowToRequestANewPassword}}</div>
                    
                    <form @submit.prevent="submitForm()">
                        <div class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} *</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="password">{{passwordLabel}} *</label>
                            <input 
                                id="password"
                                type="password"
                                autocomplete="off" 
                                v-model.trim="password"
                                @input="$v.password.$touch()"
                                :class ="{ error: $v.password.$error }"
                            />
                            <div v-if="$v.password.$dirty">
                                <p class="error" v-if="!$v.password.required">{{passwordLabel}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.password.minLength">{{passwordMinLength}}</p>
                                <p class="error" v-if="!$v.password.valid">{{passwordMustBeStronger}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="passwordConfirmation">{{confirmPasswordLabel}} *</label>
                            <input 
                                id="passwordConfirmation"
                                type="password"
                                autocomplete="off" 
                                v-model.trim="passwordConfirmation"
                                @input="$v.passwordConfirmation.$touch()"
                                :class ="{ error: $v.passwordConfirmation.$error }"
                            />
                            <div v-if="$v.passwordConfirmation.$dirty">
                                <p class="error" v-if="!$v.passwordConfirmation.required">{{confirmPasswordLabel}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.passwordConfirmation.sameAsPassword">{{incorrectPasswordConfirmation}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-center">
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{submit}}</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import agent from '../../api/agent';
import { mapGetters } from 'vuex';

export default {
    name: 'ResetPassword',
    components: {WarningMessageModal, LoadingSpinner},
    
    data() {
        return {
            isLoading: false,
            email: '',
            password: '',
            passwordConfirmation: '',
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
        }
    },

    validations: {
        email: { required, email },
        password: {
            required,
            minLength: minLength(8),
            valid: (value) => {
                const containsUppercase = /[A-Z]/.test(value);
                const containsLowercase = /[a-z]/.test(value);
                const containsNumber = /[0-9]/.test(value);
                const containsSpecial = /[:;.,_()'/#?!@$%^&*-]/.test(value);
                return (
                    containsUppercase &&
                    containsLowercase &&
                    containsNumber &&
                    containsSpecial
                ); 
            }
        },
        passwordConfirmation: { required, sameAsPassword: sameAs("password") },
    },

    created() {
       this.setDocumentTitle();
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters([
            'isAuthenticated',
            'metaTitle',
            'recaptchaValidation',
            // Texts 
            'isRequired', 
            'indicatesRequiredField', 
            'notAllRequiredFieldsAreCompleted', 
            'emailAddress', 
            'validEmailAddress',
            'passwordMinLength',
            'createYourPassword',
            'needToCreateAPassword',
            'incorrectPasswordConfirmation',
            'passwordMustBeStronger',
            'fillInTheFormBelowToRequestANewPassword',
            'submit' 
        ]),
        ...mapGetters({
            passwordLabel: 'password',
            resetPasswordLabel: 'resetPassword',
            confirmPasswordLabel: 'confirmPassword',
        })
    },

    methods: {
        setDocumentTitle() {
            document.title = this.resetPasswordLabel + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        async resetPassword() {
            try {
                const body = {
                    email: this.email,
                    resetToken: this.$route.params.resetToken,
                    password: this.password
                };
                await agent.users.resetPassword(body);
                this.isLoading = false;
                this.$router.push('/login');
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail || error.response.data.errors.ResetToken[0];
                this.showModal();
            }
        },

        async validateRecaptcha(token) {
            await this.$store.dispatch('validateRecaptcha', token);         
            if (this.recaptchaValidation === true) {
                this.resetPassword();
            } else {
                this.isLoading = false;
                this.errorTitle = 'Something went wrong';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        async getRecaptchaToken() {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.validateRecaptcha(token);
        },

        submitForm() {
            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.password.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.passwordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.passwordConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmPasswordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if(!this.$v.$invalid) {
                this.getRecaptchaToken();
            }
        }
    }
}
</script>